// envCheckerPlugin.js
export  function isWatchWork () {
            // 检查是否在微信或企业微信中
            const userAgent = navigator.userAgent.toLowerCase();
            const isWeChatOrWeChatWork = userAgent.includes('micromessenger') || userAgent.includes('wxwork');

            // 检查是否在微信小程序的 WebView 中（这种方法可能不是100%可靠）
            // 注意：window.__wxjs_environment 是在微信小程序的 WebView 中特有的
            const isWeChatMiniProgramWebView = typeof window.__wxjs_environment === 'string' && window.__wxjs_environment === 'miniprogram';

            // 如果既不在微信/企业微信中，也不在微信小程序的 WebView 中，则返回 true
            return !isWeChatOrWeChatWork && !isWeChatMiniProgramWebView;


}