import { createApp } from "vue";
import App from "./App.vue";
import router from "./router/index";
import { createPinia } from "pinia";
import vant from "vant";
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
import "vant/lib/index.css";
import "../../assets/font/index.css";
import {isWatchWork} from "@/ulits/plugins/wechatEnvPlugin";
// 在main.js中：

const app = createApp(App);
app.use(router);
app.use(createPinia());
app.use(ElementPlus);
app.use(vant);

if (!isWatchWork()){
    app.mount("#index");
}
